(function ($) {
  "use strict";

  $(document).ready(function () {
    /*-------------------------------
            Navbar Fix
        ------------------------------*/
    if ($(window).width() < 991) {
      navbarFix();
    }
    /*------------------------------
            smoth achor effect
        ------------------------------*/
    $(document).on("click", ".navbar-nav li a", function (e) {
      let anchor = $(this).attr("href");
      let link = anchor.slice(0, 1);
      if ("#" == link) {
        e.preventDefault();
        let top = $(anchor).offset().top;
        $("html, body").animate(
          {
            scrollTop: $(anchor).offset().top,
          },
          1000
        );
        $(this)
          .parent()
          .addClass("current-menu-item")
          .siblings()
          .removeClass("current-menu-item");
      }
    });

    new WOW().init();

    $(document).on("click", ".back-to-top", function () {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        100
      );
    });

    let $mosueOverEffect = $(".outer");
    if ($mosueOverEffect.length > 0) {
      VanillaTilt.init(document.querySelectorAll(".outer"), {
        max: 80,
        speed: 400,
        perspective: 200,
        scale: 1.2,
        reverse: true,
      });
    }
  });

  let lastScrollTop = "";

  $(window).on("scroll", function () {
    //back to top show/hide
    let ScrollTop = $(".back-to-top");
    if ($(window).scrollTop() > 1000) {
      ScrollTop.fadeIn(1000);
    } else {
      ScrollTop.fadeOut(1000);
    }

    // Sticky-Memu
    if ($(window).width() > 991) {
      StickyMenu();
    }
  });

  $(window).on("resize", function () {
    if ($(window).width() < 991) {
      navbarFix();
    }
  });

  $(window).on("load", function () {
    let backtoTop = $(".back-to-top");
    backtoTop.fadeOut();
  });

  function navbarFix() {
    $(document).on(
      "click",
      ".navbar-area .navbar-nav li.menu-item-has-children>a, .navbar-area .navbar-nav li.appside-megamenu>a",
      function (e) {
        e.preventDefault();
      }
    );
  }

  function StickyMenu() {
    let st = $(this).scrollTop();
    let mainMenuTop = $(".navbar-area");

    if ($(window).scrollTop() > 1000) {
      mainMenuTop.addClass("nav-fixed");
    } else {
      mainMenuTop.removeClass("nav-fixed ");
    }

    lastScrollTop = st;
  }
})(jQuery);
